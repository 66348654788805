
import Vue from "vue";
import { Route } from "vue-router";

import { Breadcrumb, Owner } from "@/interfaces";
import http from "@/http";

export interface DataType {
  owner?: Owner;
}

export default Vue.extend({
  data(): DataType {
    return {
      owner: undefined
    };
  },
  computed: {
    breadcrumbs(): Breadcrumb[] {
      if (this.owner == null) {
        return [];
      }

      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/"
        },
        {
          text: "オーナー",
          disabled: false,
          to: "/owners"
        },
        {
          text: `# ${this.owner.id} ${this.owner.name}`,
          disabled: true,
          to: `/owner/${this.owner.id}`
        }
      ];
    }
  },
  watch: {
    async $route() {
      const url = `/owners/${this.$route.params.id}`;

      const { data } = await http.get<Owner>(url);

      this.owner = data;
    }
  },
  async beforeRouteEnter(to: Route, from: Route, next: any) {
    const url = `/owners/${to.params.id}`;

    const { data } = await http.get<Owner>(url);

    next((vm: Vue & DataType) => {
      vm.owner = data;
    });
  }
});
